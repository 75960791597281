.loading-spinner{
    position: fixed;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: 5;
    display: grid;
    justify-items: center;
    align-items: center;
}
.loding_spinner_icon{
    display: grid;
    justify-items: center;
    align-items: center;
    scale: 1.5;
}
.loding_spinner_icon h4 {
    background-color: #fff;
    padding: 0.5rem;
    border: 1px solid #4CAF50;
    border-radius: 8px;
}