/* body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } */
  .react-pdf__Page__textContent ,.react-pdf__Page__annotations{
    display: none !important;
  }
  .react-pdf__Page {
    width: 258px !important;
}
  canvas.react-pdf__Page__canvas {
    width: 100% !important;
    height: auto  !important;
}
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  .ssss{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    scale: 1.2;
  }
  button{
    
    background-color: teal;
    color: #ffffff;
    /* width: 2rem;
    
    height: 1rem; */
    font-size: 1rem;
    padding: 1rem;
    margin: 1rem;
  }
  .btnr{
    display: flex;
  
  }