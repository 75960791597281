.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    z-index: 500;
    
}

.modal-content {
    scale: 1;
    border-radius: 1.25rem;
    background: var(--White, #FFF);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    overflow-x: auto;
    overflow-y: hidden;
    height: auto;
    width: auto;
    position: relative;
    transition: 0.2s ease-in-out;
}

.modal-content-wrapper {
    padding: 1rem;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
.modal-header{
    background-image: linear-gradient(to right, #3e8030, #92a54b);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
}
@media (max-width: 768px) {

    .modal-content {
        width: 100%;
    }

    .modal-overlay {
        padding: 1rem 0rem;
    }
}